import {
	SET_USER_DATA,
	CLEAR_USER_DATA,
	SET_SELECTED_BUSINESS
} from '../../redux/actionTypes'

export const setUser = (userData) => {
	return ({
		type: SET_USER_DATA,
		payload: { name: 'John Doe', email: 'test@gmail.com' }
	})
}
export const clearUser = () => {
	return ({
		type: CLEAR_USER_DATA
	})
}

export const setSelectedBusiness = (BusinessId) => {
	return ({
		type: SET_SELECTED_BUSINESS,
		payload: { BusinessId }
	})
}
