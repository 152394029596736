import React, { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { Button, Form, FormGroup, Label, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import _ from 'lodash'
import { toast } from 'react-toastify';
import { XCircle } from 'react-feather';
import { fetchDisplayMessagesList, fetchDisplayList, fetchDisplayMessageTypes, removeMessage } from '../../../redux/displays/action.js'
import displayApi from '../../../api/displays.js'
import { SET_DISPLAYS } from '../../../redux/actionTypes.js'

const DisplayMessages = () => {
	const dispatch = useDispatch();
	const displays = useSelector(content => content.Displays.displays);
	// console.log('DISPLAYS: ', displays)
	const displayKeys = Object.keys(displays)
	// const displayMessages = useSelector(content => content.Displays.messages);

	// const displayMessageTypes = useSelector(content => content.Displays.messageTypes);
	const displaysRunning = useSelector((state) => state.Displays.starts.displays);
	// const messagesRunning = useSelector((state) => state.Displays.starts.messages);
	//console.log('displayKeys: ', displayKeys)
	const [activeTab, setActiveTab] = useState(displayKeys[0]);
	const BusinessId = useSelector(content => content.User.currentBusinessId);
	const [formdata, setFormdata] = useState({});

	const imgThumbNailStyles = {
		width: '100%',
		height: '100%',
		objectFit: 'contain'
	}

	const thumbContainerStyles = {
		position: 'relative',
		textAlign: 'center'
	}

	const thumbCloseButton = {
		position: 'absolute',
		top: '2px',
		right: '2px',
		cursor: 'pointer',
		zIndex: 1000,
		color: '#f00'
	}

	const thumbName = {
		textAlign: 'center',
		fontWeight: 'bold',
		marginTop: '5px'
	}

	const imgNoThumbStyles = {
		width: '100%',
		height: '100%'
	}
	const imgNoThumbTextStyles = {
		color: '#999',
		paddingTop: '20px'
	}

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm({
		defaultValues: {
			messageType: 'PetCheckin'
		}
	});

	useEffect(() => {
		// if (!messagesRunning) {
		// 	dispatch(fetchDisplayMessagesList())
		// }
		if (!displaysRunning) {
			dispatch(fetchDisplayList())
		}
		// dispatch(fetchDisplayMessageTypes())
	}, [displaysRunning, dispatch]);

	async function closeMessage (MessageId) {
		const result = await displayApi.closeDisplayMessage(BusinessId, MessageId)
		if (result.isOk) {
			toast.success(result.message)
			dispatch(removeMessage(MessageId, 'PetCheckin'))
			updateDisplayList()
		} else {
			result.errors.forEach(error => {
				toast.error(error)
			})
		}
	}

	async function updateDisplayList () {
		const displays = await displayApi.fetchDisplaysList(BusinessId);
		dispatch({ type: SET_DISPLAYS, displays });
	}


	async function onCreateMessageSubmit(data) {
		const finalMessageData = {
			displays: [parseInt(data.displays)],
			messageType: data.messageType,
			params: {
				petId: data.petId
			}
		}

		const result = await displayApi.createDisplayMessage(BusinessId, finalMessageData)
		// console.log('RESULT: ', result)
		if (result.isOk) {
			toast.success(result.message)
			reset()
			updateDisplayList()
		} else {
			result.errors.forEach(error => {
				toast.error(error)
			})
		}
	};

	return (
		<Fragment>
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-12 xl-100">
						<div className="card">
							<div className="card-header">
								<h5>Checkin a dog</h5>
							</div>
							<div className="card-body">
								<Form onSubmit={handleSubmit(onCreateMessageSubmit)} className="needs-validation">
									<FormGroup className="mb-3">
										<input className={'form-control'} id="messageType" type="hidden" name="messageType" {...register("messageType")} />
										{ /*<div className="row mb-10">
											<div className="col-xl-3">
												<Label htmlFor="messageType">Message Type</Label>
											</div>
											<div className="col-xl-9">
												<select className="form-control" id="messageType" name="messageType" {...register("messageType", { required: true })} >
													{(displayMessageTypes?.length) && (
														displayMessageTypes.map(mt => (<option value={mt.DisplayMessageTypeKey}>{mt.label}</option>))
													)}
												</select>
											</div>
										</div> */ }
										<div className="row mb-10 gy-1">
											<div className="col-xl-3 col-md-3 col-sm-3">
												<Label htmlFor="petId">Pet ID:</Label>
												<input className={`form-control ${errors.petIds && "is-invalid"}`} id="petId" type="number" name="petIds" defaultValue={formdata.petIds || ""} {...register("petId", { required: true, valueAsNumber: true })} />
												<span className="text-danger">{errors.petIds && "Pet ID must be a number"}</span>
											</div>
											<div className="col-xl-4 col-md-4 col-sm-4">
												<p style={{ fontSize: '14px' }}>Room:</p>
												{displayKeys.map(displayKey => (
													<label className="d-block" htmlFor="displays">
														<input className="radio_animated" id="displays" type="radio" value={displayKey} disabled={(displays[displayKey]?.messages?.PetCheckin?.length >= 48)} {...register("displays", { required: true })} />
														<span>{displays[displayKey].label}</span>
														{((displays[displayKey]?.messages?.PetCheckin?.length >= 48)) && (
															<span className="ml-20" style={{ color: '#f00' }}>Limit of 48 reached.</span>
														)}
													</label>
												))}
												<span className="text-danger">{errors.displays && "You must select at least one room"}</span>
											</div>

										</div>
										<div className="row mb-10">
											<div className="col-xl-12">
												<Button className="primary" type="submit">
													Submit
												</Button>

											</div>
										</div>



									</FormGroup>
								</Form>
							</div>
							<div className="card-body">

							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-12 xl-100">
						<div className="card">
							<div className="card-header">
								<h5>Pet Checkins</h5>
							</div>
							<div className="card-body">
								{(displayKeys.length === 0) && (
									<p className="text-center">No active displays found</p>
								)}
								{(displayKeys.length > 0) && (
									<div>
										<Nav className="nav-primary" tabs>
											{(displayKeys.map(dispKey => {
												const display = displays[dispKey]
												return (
													<NavItem>
														<NavLink href="#javascript" className={activeTab === dispKey ? 'active' : ''} onClick={() => setActiveTab(dispKey)}><i className="icofont icofont-ui-home"></i>{display?.label}</NavLink>
													</NavItem>
												)
											}))}

										</Nav>
										<TabContent activeTab={activeTab}>
											{(displayKeys.map(dispKey => {
												const display = displays[dispKey]
												const messages = display?.messages?.PetCheckin
												return (
													<TabPane className="fade show pt-10" tabId={dispKey}>
														{(!messages?.length) && (
															<p className="text-center m-20 f-18">No dogs checked in to this room.</p>
														)}
														{(messages?.length) && (
															<div className="row">
																{(messages.map((message, index) => {
																	return (
																		<div className="col-lg-1 col-xl-1 col-md-1 col-sm-1">
																			<div style={thumbContainerStyles}>
																				<div style={thumbCloseButton}>
																					<XCircle size={20} onClick={() => closeMessage(message._id)} />
																				</div>
																				<div className="inline-block text-center" style={{height: '100%'}}>
																					<div style={{ backgroundColor: '#ddd', textAlign: 'center', height: 85 }}>
																						{(message.data.image) && (
																							<img src={message.data.image} style={imgThumbNailStyles} alt={message.data.first_name} />
																						)}
																						{(!message.data.image) && (
																							<div style={imgNoThumbStyles}>
																								<p style={imgNoThumbTextStyles}>No Image Available</p>
																							</div>
																						)}
																					</div>
																					<p style={thumbName}>{message.data.first_name}</p>

																				</div>

																			</div>
																		</div>
																	)
																}))}
															</div>
														)}
													</TabPane>
												)
											}))}
										</TabContent>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>

			</div>
		</Fragment>
	);
};

export default DisplayMessages;
