import React, { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { Button, Form, FormGroup, Label } from "reactstrap";
import _ from 'lodash'
import { toast } from 'react-toastify';
// import Breadcrumb from "../../common/breadcrumb.js";
import { XCircle } from 'react-feather';
import { fetchDisplayMessagesList, fetchDisplayList, fetchDisplayMessageTypes, removeMessage, setHeader } from '../../../redux/displays/action.js'
import displayApi from '../../../api/displays.js'
import { SET_DISPLAYS } from '../../../redux/actionTypes.js'

const DisplayMessages = () => {
	const dispatch = useDispatch();
	const displays = useSelector(content => content.Displays.displays);
	// console.log('DISPLAYS: ', displays)
	const displayKeys = Object.keys(displays)
	// const displayMessages = useSelector(content => content.Displays.messages);
	// const displayMessageTypes = useSelector(content => content.Displays.messageTypes);
	const displaysRunning = useSelector((state) => state.Displays.starts.displays);
	// const messagesRunning = useSelector((state) => state.Displays.starts.messages);
	const BusinessId = useSelector(content => content.User.currentBusinessId);
	const [selectedFormActionType, setSelectedFormActionType] = useState(null);
	const [formdata, setFormdata] = useState({});

	const imgThumbNailStyles = {
		maxWidth: '200px',
		maxHeight: '200px',
	}

	const thumbContainerStyles = {
		position: 'relative',
		width: '210px',
		padding: '5px',
		textAlign: 'center'
	}

	const thumbCloseButton = {
		position: 'absolute',
		top: '10px',
		right: '10px',
		cursor: 'pointer',
		zIndex: 1000,
		color: '#f00'
	}

	const thumbName = {
		textAlign: 'center',
		fontWeight: 'bold',
		marginTop: '10px'
	}

	const thumbCell = {
		width: '200px'
	}

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm({
		defaultValues: {
			messageType: 'PetPickup',
			actionType: 'replace'
		}
	});

	const handleActionTypeChange = (event) => {
		setSelectedFormActionType(event.target.value);
	}

	useEffect(() => {
		// if (!messagesRunning) {
		// 	dispatch(fetchDisplayMessagesList())
		// }
		if (!displaysRunning) {
			dispatch(fetchDisplayList())
		}
	}, [displaysRunning, dispatch]);

	async function closeMessage (MessageId) {
		const result = await displayApi.closeDisplayMessage(BusinessId, MessageId)
		if (result.isOk) {
			toast.success(result.message)
			dispatch(removeMessage(MessageId, 'PetPickup'))
			updateDisplayList()
		} else {
			result.errors.forEach(error => {
				toast.error(error)
			})
		}
	}

	async function updateDisplayList () {
		const displays = await displayApi.fetchDisplaysList(BusinessId);
		dispatch({ type: SET_DISPLAYS, displays });
	}


	async function onCreateMessageSubmit(data) {
		const finalMessageData = {
			displays: [parseInt(data.displays)],
			messageType: data.messageType,
			params: {
				petId: data.petId
			},
			actionType: data.actionType
		}

		const result = await displayApi.createDisplayMessage(BusinessId, finalMessageData)
		// console.log('RESULT: ', result)
		if (result.isOk) {
			toast.success(result.message)
			reset()
			updateDisplayList()
		} else {
			result.errors.forEach(error => {
				toast.error(error)
			})
		}
	};

	return (
		<Fragment>
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-12 xl-100">
						<div className="card">
							<div className="card-header">
								<h5>Messages</h5>
							</div>
							<div className="card-body">
								{(displayKeys.length === 0) && (
									<p className="text-center">No active displays found</p>
								)}
								{(displayKeys.length > 0) && (
									<div className="table-responsive">
										<table className="table table-bordered">
											<tbody>
												{(displayKeys.map((dispKey) => {
													const display = displays[dispKey]
													return (<tr>
														<th style={thumbCell}>{display?.label}</th>
														{[0,1,2,3].map(index => {
															return (
																<td style={thumbCell}>
																	{(display.messages?.PetPickup?.[index]) && (
																		<div style={thumbContainerStyles}>
																			<div style={thumbCloseButton}>
																				<XCircle size={30} onClick={() => closeMessage(display.messages?.PetPickup[index]?._id)} />
																			</div>
																			<div className="inline-block text-center">
																				<div style={{ width: '200px', height: '200px', backgroundColor: '#ddd', textAlign: 'center' }}>
																					{(display.messages?.PetPickup[index].data.image) && (
																						<img src={display.messages?.PetPickup[index].data.image} style={imgThumbNailStyles} alt={display.messages?.PetPickup[index].data.first_name} />
																					)}
																					{(!display.messages?.PetPickup[index].data.image) && (
																						<p style={{color: '#999', paddingTop: '80px'}}>No Image Available</p>
																					)}
																				</div>
																				<p style={thumbName}>{display.messages?.PetPickup[index].data.first_name}</p>

																			</div>

																		</div>

																	)}
																</td>
															)
														})}

													</tr>)
												}))}
											</tbody>
										</table>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-12 xl-100">
						<div className="card">
							<div className="card-header">
								<h5>Send Dog Pickup Message to Display</h5>
							</div>
							<div className="card-body">
								<Form onSubmit={handleSubmit(onCreateMessageSubmit)} className="needs-validation">
									<FormGroup className="mb-3">
										<input className={'form-control'} id="messageType" type="hidden" name="messageType" {...register("messageType")} />
										{ /*<div className="row mb-10">
											<div className="col-xl-3">
												<Label htmlFor="messageType">Message Type</Label>
											</div>
											<div className="col-xl-9">
												<select className="form-control" id="messageType" name="messageType" {...register("messageType", { required: true })} >
													{(displayMessageTypes?.length) && (
														displayMessageTypes.map(mt => (<option value={mt.DisplayMessageTypeKey}>{mt.label}</option>))
													)}
												</select>
											</div>
										</div> */ }
										<div className="row mb-10 gy-1">
											<div className="col-xl-3 col-md-3 col-sm-3">
												<Label htmlFor="petId">Pet ID:</Label>
												<input className={`form-control ${errors.petIds && "is-invalid"}`} id="petId" type="number" name="petIds" defaultValue={formdata.petIds || ""} {...register("petId", { required: true, valueAsNumber: true })} />
												<span className="text-danger">{errors.petIds && "Pet ID must be a number"}</span>
											</div>
											<div className="col-xl-2 col-md-2 col-sm-2">
												<Label htmlFor="messageType">Action:</Label>
												<select className="form-control" id="actionType" name="actionType" {...register("actionType", { required: true })} onChange={(e) => handleActionTypeChange(e)} >
													<option value="replace">Replace All</option>
													<option value="append">Add to list</option>
												</select>
											</div>
											<div className="col-xl-4 col-md-4 col-sm-4">
												<p style={{fontSize: '14px'}}>Room:</p>
												{displayKeys.map(displayKey => (
													<label className="d-block" htmlFor="displays">
														<input className="radio_animated" id="displays" type="radio" value={displayKey} disabled={(selectedFormActionType === 'append' && displays[displayKey].messages.length > 3)} {...register("displays", { required: true })} />
														<span>{displays[displayKey].label}</span>
														{((selectedFormActionType === 'append' && displays[displayKey].messages.length > 3)) && (
															<span className="ml-20" style={{ color: '#f00' }}>Limit of 4 reached.</span>
														)}
													</label>
												))}
												<span className="text-danger">{errors.displays && "You must select at least one room"}</span>
											</div>

										</div>
										<div className="row mb-10">
											<div className="col-xl-12">
												<Button className="primary" type="submit">
													Submit
												</Button>

											</div>
										</div>



									</FormGroup>
								</Form>
							</div>
							<div className="card-body">

							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default DisplayMessages;
