import React, { useEffect, Fragment } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"
import _ from 'lodash'
import { fetchDisplayList  } from '../../redux/displays/action.js'
import PetPickupDisplay from './monitor/petPickup.js'
import PetCheckinDisplay from './monitor/petCheckin.js'

const DisplayMonitor = () => {
	const dispatch = useDispatch();
	const displays = useSelector(content => content?.Displays?.displays);
	const displaysRunning = useSelector((state) => state?.Displays?.starts?.displays);
	const { displayId } = useParams();
	const thisDisplay = displays[displayId]

	let displayType = null
	if (!_.isEmpty(thisDisplay?.messages)) {
		if (thisDisplay.messages?.PetPickup?.length) {
			displayType = 'PetPickup'
		} else if (thisDisplay.messages?.PetCheckin?.length) {
			displayType = 'PetCheckin'
		}
	}

	useEffect(() => {
		if (!displaysRunning) {
			dispatch(fetchDisplayList())
		}
		document.querySelector(".sidebar-resizer").classList.add('d-none');
		document.querySelector(".page-sidebar").classList.add('d-none');
		document.querySelector(".page-body").classList.add('ml-0');
		document.querySelector(".page-main-header").classList.add('ml-0');


		return () => {
			document.querySelector(".sidebar-resizer").classList.remove('d-none');
			document.querySelector(".page-sidebar").classList.remove('d-none');
			document.querySelector(".page-body").classList.remove('ml-0');
			document.querySelector(".page-main-header").classList.remove('ml-0');
		}
	}, []);

	return (
		<Fragment>
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-12 xl-100">
						<div className="card">
							{/*<div className="card-header" style={{padding: '10px'}}>
								<h5>Display: {thisDisplay?.label ?? 'No Display Selected'}</h5>
							</div>*/}
							{(!displayType) && (
								<div className="card-body text-center">
									<h5>There are currently no messages to display</h5>
								</div>
							)}
							{(displayType === 'PetPickup') && (
								<PetPickupDisplay/>
							)}
							{(displayType === 'PetCheckin') && (
								<PetCheckinDisplay />
							)}

						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default DisplayMonitor;
