import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { setHeader } from '../../../redux/displays/action.js'

const DisplayMonitorPetPickup = () => {
	const displays = useSelector(content => content?.Displays?.displays);
	const { displayId } = useParams();
	const thisDisplay = displays[displayId]
	const messages = thisDisplay?.messages?.PetPickup
	const messageCount = messages?.length
	const rowCount = messageCount ? Math.round(messageCount / 2) : 1
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setHeader('Pickup'))
		return () => {
			dispatch(setHeader(''))
		}
	}, [dispatch]);

	return (
		<Fragment>
			{(messages?.length > 0) && (
				<div id="PetPickupDisplayWrapper" className="card-body" style={{padding:0}}>
					<div className={`display-img-grid-container display-img-grid-${messageCount} display-img-grid-container-rows-${rowCount}`}>
						{messages.map(message => {
							return (<div className="display-img-grid-item">
								<div className="row">
									<div className="col">
										<div className="displayMonitor-pet-name-wrapper">
											<h1>{message?.data?.first_name}</h1>
										</div>
										<div className="displayMonitor-owner-wrapper">
											<table className="table table-borderless text-24">
												<tbody>
													<tr>
														<th>Breed:</th>
														<td>{(message?.data?.breed?.label ?? 'Unknown')}</td>
														<th>Owner:</th>
														<td>{message?.data?.owner_first_name} {message?.data?.owner_last_name}</td>
													</tr>
												</tbody>
											</table>
										</div>
										{(message?.data?.image) && (
											<div className="display-img-image-wrapper">
												<img src={message?.data?.image} alt={message?.data?.first_name} />
											</div>
										)}
										{(!message?.data?.image) && (
											<div className="center no-image">
												<h2>No Image Available</h2>
											</div>
										)}
									</div>
								</div>
							</div>
						)})}

					</div>
				</div>
			)}
		</Fragment>
	);
};

export default DisplayMonitorPetPickup;
