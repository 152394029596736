import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

const DisplayMonitorPetCheckin = () => {
	const displays = useSelector(content => content?.Displays?.displays);
	const { displayId } = useParams();
	const thisDisplay = displays[displayId]
	const messages = thisDisplay?.messages?.PetCheckin

	const messageCount = messages?.length ?? 0

	let imgHeight = 150  // default 4 rows of 12
	let colSpan = 1
	let nameFontSize = 16

	if (messageCount === 1) {
		colSpan = 12
		imgHeight = 780
		nameFontSize = 48
	} else if (messageCount === 2) {
		colSpan = 6
		imgHeight = 780
		nameFontSize = 48
	} else if (messageCount <= 4) { // 2 rows of 2
		colSpan = 6
		imgHeight = 350
		nameFontSize = 36
	} else if (messageCount <= 6) { // 2 rows of 3
		colSpan = 4
		imgHeight = 350
		nameFontSize = 32
	} else if (messageCount <= 8) { // 2 rows of 4
		colSpan = 3
		imgHeight = 350
		nameFontSize = 28
	} else if (messageCount === 9) { // 3 rows of 3
		colSpan = 4
		imgHeight = 225
		nameFontSize = 28
	} else if (messageCount <= 12) { // 3 rows of 4
		colSpan = 3
		imgHeight = 225
		nameFontSize = 24
	} else if (messageCount <= 18) { // 3 rows of 6
		colSpan = 2
		imgHeight = 225
		nameFontSize = 18
	}

	const colClass = `col-lg-${colSpan} col-xl-${colSpan} col-md-${colSpan} col-sm-${colSpan}`

	const thumbWrapper = {
		backgroundColor: '#ddd',
		textAlign: 'center',
		height: imgHeight
	}

	const imgThumbNailStyles = {
		width: '100%',
		height: '100%',
		objectFit: 'contain'
	}

	const thumbContainerStyles = {
		position: 'relative',
		textAlign: 'center',
		marginBottom: '10px'
	}

	const thumbName = {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: `${nameFontSize}px`,
		// marginTop: 5,
		lineHeight: '1.1em',
		padding: 3,
		color: '#0f0',
		backgroundColor: '#333'
	}

	const imgNoThumbStyles = {
		width: '100%',
		height: '100%'
	}
	const imgNoThumbTextStyles = {
		color: '#999',
		padding: '20px 5px 0 5px'
	}

	useEffect(() => {
	}, []);

	return (
		<Fragment>
			{(messages?.length > 0) && (
				<div id="PetCheckinDisplayWrapper" className="card-body">
					{(messages?.length) && (
						<div className="row gy-0">
							{(messages.map((message, index) => {
								return (
									<div className={colClass}>
										<div style={thumbContainerStyles}>
											<div className="inline-block text-center" style={{ height: '100%', width: '100%' }}>
												<div style={thumbWrapper}>
													{(message.data.image) && (
														<img src={message.data.image} style={imgThumbNailStyles} alt={message.data.first_name} />
													)}
													{(!message.data.image) && (
														<div style={imgNoThumbStyles}>
															<p style={imgNoThumbTextStyles}>No Image Available</p>
														</div>
													)}
												</div>
												<p style={thumbName}>{message.data.first_name}</p>

											</div>

										</div>
									</div>
								)
							}))}
						</div>
					)}
				</div>
			)}
		</Fragment>
	);
};

export default DisplayMonitorPetCheckin;
