import { fetch, fetchPost, fetchPut } from '../utilities/utilities.js';
import { DATA_PATH } from '../utilities/globals';

export {
	fetchDisplaysList,
	fetchDisplayMessageTypesList,
	fetchDisplayMessagesList,
	closeDisplayMessage,
	createDisplayMessage
}

const defaultObj = {
	fetchDisplaysList,
	fetchDisplayMessageTypesList,
	fetchDisplayMessagesList,
	closeDisplayMessage,
	createDisplayMessage
}

export default defaultObj

async function fetchDisplaysList (BusinessId) {
	return fetch(`${DATA_PATH}/${BusinessId}/displays/list`);
}

async function fetchDisplayMessageTypesList() {
	return fetch(`${DATA_PATH}/displays/messages/types`);
}

async function fetchDisplayMessagesList(BusinessId) {
	return fetch(`${DATA_PATH}/${BusinessId}/displays/messages`);
}

async function closeDisplayMessage(BusinessId, MessageId) {
	return fetchPut(`${DATA_PATH}/${BusinessId}/displays/messages/${MessageId}`);
}

async function createDisplayMessage(BusinessId, data) {
	return fetchPost(`${DATA_PATH}/${BusinessId}/displays/messages`, data)
}
