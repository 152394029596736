import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import { fetchDisplayList  } from '../../redux/displays/action.js'

const ListDisplays = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const displays = useSelector(content => content.Displays.displays);
	const displaysRunning = useSelector((state) => state.Displays.starts.displays);
	const displayKeys = Object.keys(displays)

	useEffect(() => {
		if (!displaysRunning) {
			dispatch(fetchDisplayList())
		}
	}, []);

	return (
		<Fragment>
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-12 xl-100">
						<div className="card">
							<div className="card-header">
								<h5>Displays</h5>
							</div>
							{(displayKeys.length === 0) && (
								<p className="text-center">No active displays found</p>
							)}
							{(displayKeys.length > 0) && (
								<div className="table-responsive">
									<table className="table table-border-horizontal">
										<thead>
											<tr>
												<th scope="col">Actions</th>
												<th scope="col">Room</th>
											</tr>
										</thead>
										<tbody>
											{(displayKeys.map((displayKey, key) => {
												return (<tr>
													<td><button className="btn btn-primary" type="button" onClick={() => { navigate(`/displays/display/${displays[displayKey]._id}`); }}>Open</button></td>
													<td>{displays[displayKey].label}</td>
													<td>
														{(displays[displayKey].message) && (
															<p>{displays[displayKey].message.type.label}</p>
														)}
													</td>

												</tr>)
											}))}
										</tbody>
									</table>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ListDisplays;
