export class ConfigDB {
	static data = {
		settings: {
			layout_type: 'ltr',
			sidebar: {
				type: 'default',
				body_type: 'default'
			},
			sidebar_setting: 'border-sidebar',
			sidebar_backround: 'light-sidebar'
		},
		color: {
			layout_version: 'dark',
			color: 'k9-color',
			primary_color: '#0f0',
			secondary_color: '#f00',
			mix_layout: 'default'
		},
		router_animation: 'fadeIn'
	}
}

export default ConfigDB;
