import { Home, Monitor  } from "react-feather";

export const MENUITEMS = [
	{
    title: "Dashboard",
	path: "/",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
    // children: [
    //   { , title: "Default", type: "link" },
    // ],
	},
	{
		title: "List Displays",
		path: "/displays/",
		icon: Monitor,
		type: "link",
		badgeType: "primary",
		active: false
	},
	{
		title: "Actions",
		icon: Monitor,
		type: "sub",
		badgeType: "primary",
		active: true,
		children: [
			{ path: "/displays/messages/checkin", title: "Checkin", type: "link" },
			{ path: "/displays/messages/pickup", title: "PickUp", type: "link" }
		],
	}
];
