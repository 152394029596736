import axios from 'axios';

export function fetch (url) {
	return axios.get(url).then(items => {
		return items.data;
	}).catch(e => {
		return {
			success: false,
			message: e
		};
	});
};

export function fetchPost (url, data) {
	return axios.post(url, data).then(items => {
		return items.data;
	}).catch(e => {
		return {
			success: false,
			message: e
		};
	});
};

export function fetchPut(url, data) {
	return axios.put(url, data).then(items => {
		return items.data;
	}).catch(e => {
		return {
			success: false,
			message: e
		};
	});
};
