import { call, put, takeLatest, take, fork, select, delay } from "redux-saga/effects";
import { fetchDisplaysList, fetchDisplayMessageTypesList, fetchDisplayMessagesList } from "../../api/displays.js";
import {
    SET_DISPLAYS,
	SET_DISPLAY_MESSAGE_TYPES,
	SET_DISPLAY_MESSAGES,
	WATCH_DISPLAY_LIST,
	WATCH_DISPLAY_MESSAGE_TYPES,
	WATCH_DISPLAY_MESSAGES,
	SET_RUNNING_DISPLAY_LIST,
	CLEAR_RUNNING_DISPLAY_LIST,
	SET_RUNNING_MESSAGES_LIST,
	CLEAR_RUNNING_MESSAGES_LIST,
	SET_RUNNING_MESSAGE_TYPES_LIST,
	CLEAR_RUNNING_MESSAGE_TYPES_LIST } from "../actionTypes.js";
import { DATA_REFRESH_INTERVAL_SMALL, DATA_REFRESH_INTERVAL_LONG } from '../../utilities/globals.js'


function* fetchDisplayListAsync() {
  const running = yield select((state) => state.Displays.starts.displays);
	if (running) {
		return;
	}

  yield put({ type: SET_RUNNING_DISPLAY_LIST });
  try {
    while (true) {
      const BusinessId = yield select((state) => state.User.currentBusinessId);
      const Data = yield call(fetchDisplaysList, BusinessId);
      yield put({ type: SET_DISPLAYS, displays: Data });
      yield delay(DATA_REFRESH_INTERVAL_SMALL);
    }
  } catch (error) {
    console.error('Error fetching display list:', error);
  } finally {
    yield put({ type: CLEAR_RUNNING_DISPLAY_LIST });
  }
}

function* fetchDisplayMessagesListAsync() {
  let running = yield select((state) => state.Displays.starts.messages);
	if (running) {
		return;
	}

  yield put({ type: SET_RUNNING_MESSAGES_LIST });
  try {
    while (true) {
      const BusinessId = yield select((state) => state.User.currentBusinessId);
      const Data = yield call(fetchDisplayMessagesList, BusinessId);
      yield put({ type: SET_DISPLAY_MESSAGES, messages: Data });
      yield delay(DATA_REFRESH_INTERVAL_SMALL);
    }
  } catch (error) {
    console.error('Error fetching display messages list:', error);
  } finally {
    yield put({ type: CLEAR_RUNNING_MESSAGES_LIST });
  }
}

function* fetchDisplayMessageTypesListAsync() {
  const running = yield select((state) => state.Displays.starts.messageTypes);
  if (running) {
		return;
	}

  yield put({ type: SET_RUNNING_MESSAGE_TYPES_LIST });
  try {
    while (true) {
      const Data = yield call(fetchDisplayMessageTypesList);
			yield put({ type: SET_DISPLAY_MESSAGE_TYPES, messageTypes: Data });
      yield delay(DATA_REFRESH_INTERVAL_LONG);
    }
  } catch (error) {
    console.error('Error fetching display message types list:', error);
  } finally {
    yield put({ type: CLEAR_RUNNING_MESSAGE_TYPES_LIST });
  }
}

export function* watchDisplayList() {
	yield takeLatest(WATCH_DISPLAY_LIST, fetchDisplayListAsync);
}

export function* watchDisplayMessagesList() {
	yield takeLatest(WATCH_DISPLAY_MESSAGES, fetchDisplayMessagesListAsync);
}

export function* watchDisplayMessageTypesList() {
	yield takeLatest(WATCH_DISPLAY_MESSAGE_TYPES, fetchDisplayMessageTypesListAsync);
}
