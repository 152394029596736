import { combineReducers } from 'redux';
import Displays from './displays/reducer.js'
import Customizer from './customizer/reducer';
import User from './user/reducer.js';

const reducers = combineReducers({
	Displays,
	User,
    Customizer
});

export default reducers;
