import React from "react";
import Default from "../components/dashboard/dashboard.js";
import ListDisplays from '../components/displays/listDisplays.js'
import DisplayMonitor from '../components/displays/displayMonitor.js'
import PetPickup from '../components/displays/manage/petPickup.js'
import PetCheckin from '../components/displays/manage/petCheckin.js'

export const routes = [
	{ path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Default /> },
	{ path: `${process.env.PUBLIC_URL}/displays`, Component: <ListDisplays /> },
	{ path: `${process.env.PUBLIC_URL}/displays/display/:displayId`, Component: <DisplayMonitor /> },
	{ path: `${process.env.PUBLIC_URL}/displays/messages/checkin`, Component: <PetCheckin /> },
	{ path: `${process.env.PUBLIC_URL}/displays/messages/pickup`, Component: <PetPickup /> }
];
