import React, { useEffect, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import Chart from '../examples/charts/googleChart.js'

const Default = () => {
	return (
		<Fragment>
			<Breadcrumb parent="Dashboard" title="Dashboard" />
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-12 xl-100">
						<Chart />
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Default;
